// import * as dotenv from 'dotenv';
import axiosInterceptors from "../axiosHandler";
// dotenv.config();
const baseURL = "https://api.importjdmmagic.com";
// const baseURL = "http://localhost:4545";

export const carSearch = (data) => {
  return axiosInterceptors()
    .get(baseURL + "/cars/search?" + data)
    .then((res) => res);
};

export const getCarDetails = (data) => {
  return axiosInterceptors()
    .post(baseURL + "/cars/detail", data)
    .then((res) => res);
};

export const getCarFilters = (data) => {
  return axiosInterceptors()
    .get(baseURL + "/cars/filters?" + data)
    .then((res) => res);
};

export const getTranslatedAuctionSheet = (data) => {
  return axiosInterceptors().post(
    baseURL + "/cars/getTranslatedAuctionSheet",
    data
  );
};

export const signup = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/auth/signup", data)
    .then((res) => res);
};

export const signin = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/auth/login", data)
    .then((res) => res);
};

export const forgotPassword = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/auth/forgot-password", data)
    .then((res) => res);
};

export const verifyOTP = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/auth/verify-otp", data)
    .then((res) => res);
};

export const changePassword = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/users/change-password", data)
    .then((res) => res);
};

export const updateProfile = async (data) => {
  return axiosInterceptors()
    .put(baseURL + "/users", data)
    .then((res) => res);
};

export const updateCarFavourite = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/cars/favorite", data)
    .then((res) => res);
};

export const getCarFavourites = async (data) => {
  return axiosInterceptors()
    .get(baseURL + "/cars/favorite?" + data)
    .then((res) => res);
};

export const sendInspectionRequest = async (data) => {
  return axiosInterceptors()
    .post(baseURL + "/email/sendInspectionRquest", data)
    .then((res) => res);
};