import { Button } from "@mui/material";
import { is, pathOr } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CarDetailsCard from "../components/car-details-card";
import Footer from "../components/footer";
import Header from "../components/header";
import FilterBar from "../components/side-filter-bar";
import withAuth from "../layouts/with-auth";
import { carSearch, getCarFilters } from "../services";
import {
  setSearchInState,
  setFilterInState,
  resetSearchFromState,
  resetFilterFromState,
} from "../actions/searchBarActions";
import Loading from "../layouts/Loading";

const CarListing = ({
  user,
  search,
  setSearchInState,
  filter,
  setFilterInState,
  clearSearchAndFilters,
}) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [totalCarsToShow, setTotalCarsToShow] = useState(8);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState(search || "");
  const [carsData, setCarsData] = useState([]);
  const [totalCars, setTotalCars] = useState(0);
  const [carFiltersList, setCarFiltersList] = useState({});
  const [filterString, setFilterString] = useState(filter ||"");

  const [isFilterBarOpen, setIsFilterBarOpen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);


  useEffect(() => {
    if(filter){
      setFilterString(filter);
    }
  }, [filter]);

  const getCarsList = useCallback(() => {
    setLoading(true);
    console.log("filterString", filterString);
    setSearchInState(searchString);
    setFilterInState(filterString);
    const payload = `pageNo=${page}&size=${totalCarsToShow}&search=${searchString}${filterString}`;
    carSearch(payload).then((res) => {
      setLoading(false);
      if (res?.message === "Success") {
        setTotalCars(res?.data[0]?.total || 0);
        if (page === 1) {
          setCarsData(res?.data[0].data);
        } else {
          setCarsData([...carsData, ...res?.data[0].data]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalCarsToShow, searchString, filterString]);

  const clearCarList = () => {
    setTotalCars(0);
    setCarsData([]);
  };
  const clearFilters = () => {
    setSearchString("");
    // setSearchInState("");
    // setFilterInState("");
    clearSearchAndFilters();
  };

  const filterHandler = (filterString) => {
      setPage(1);
      setFilterString(filterString);
    };


  useEffect(() => {
    getCarsList();
  }, [getCarsList, page, totalCarsToShow, searchString, filterString]);

  useEffect(() => {
    const payload = `!${filterString}`.replace("!&", "").replace("!", "");
    getCarFilters(payload).then((res) => {
      if (res.message === "Success") {
        setCarFiltersList(res?.data);
      }
    });
  }, [filterString]);

  // console.log("carsData", carsData);

  return (
    <div>
      <Header
        searchString={searchString}
        setSearchString={(search) => {
          setPage(1);
          setSearchString(search);
        }}
      />
      <div className="flex mt-2">
        {isFilterBarOpen && (
        <FilterBar
          setIsFilterBarOpen={setIsFilterBarOpen}
          isFilterBarOpen={isFilterBarOpen}
          carFiltersList={carFiltersList}
          filter={filterString}
          setFilterString={filterHandler}
          reloadCarList={clearCarList}
          clearFilters={clearFilters}
          windowWidth={windowWidth}
        />
        )}
        <div
          className={`flex ${isFilterBarOpen && `flex-[0.6] lg:flex-[0.8]`}  flex-col`}
          style={{ maxHeight: "calc(100vh)" }}
        >
          <div className="flex items-center justify-start p-3 sticky top-0 bg-white">
          {!isFilterBarOpen && <span className="cursor-pointer" onClick={() => setIsFilterBarOpen(true)}><svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            className="fill-[#6d747a] h-6 w-6 mr-2"
          >
            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg></span>}
            <p className="ml-2 text-[#6d747a] text-sm">{`${totalCars} matches`}</p>
          </div>
          <div
            className="flex flex-col overflow-y-scroll"
            onScroll={(e) => {
              // console.log(e.currentTarget.scrollHeight - 800 + " " + e.currentTarget.scrollTop);
              console.log(
                e.currentTarget.offsetHeight +
                  e.currentTarget.scrollTop +
                  " >= " +
                  e.currentTarget.scrollHeight
              );
              if (
                // e.currentTarget.scrollHeight - 800 < e.currentTarget.scrollTop &&
                e.currentTarget.offsetHeight + e.currentTarget.scrollTop + 1 >=
                  e.currentTarget.scrollHeight &&
                loading === false &&
                carsData.length < totalCars &&
                page <= totalCars / totalCarsToShow
              ) {
                setPage((prev) => prev + 1);
              }
            }}
          >
            <div className={`p-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${isFilterBarOpen ? 'gap-4':'gap-1'}`} style={{gridTemplateColumns: `${windowWidth < 1024 && !isFilterBarOpen && 'repeat(3, minmax(0, 1fr))' || windowWidth < 1024 && isFilterBarOpen && 'repeat(1, minmax(0, 1fr))'}`}}>
              {carsData?.map((data) => {
                return (
                  <CarDetailsCard
                    data={data}
                    key={data._id}
                    getCarList={() => {
                      getCarsList();
                    }}
                    isLoggedIn={user?.isLoggedIn}
                   isFilterBarOpen={isFilterBarOpen}
                  />
                );
              })}
            </div>
            {loading ? ( <Loading/>
            ) : (
              ""
            )}
            <div className="my-5">
              <Button
                variant="outlined"
                onClick={() => {
                  setPage((prev) => prev + 1);
                }}
              >
                SEE MORE MATCHES
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: pathOr({}, ["user"], state),
    search: pathOr("", ["searchBar", "search"], state),
    filter: pathOr("", ["searchBar", "filter"], state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchInState: (data) => {
      dispatch(setSearchInState(data));
    },
    setFilterInState: (data) => {
      dispatch(setFilterInState(data));
    },
    clearSearchAndFilters: (data) => {
      dispatch(resetSearchFromState());
      dispatch(resetFilterFromState());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(CarListing));
